import React from "react"
import { Container } from "react-bootstrap"
import "./AreaGuidesDetailBanner.scss"
import ScrollAnimation from "react-animate-on-scroll"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const {
  CTALink,
} = require("@starberry/gatsby-theme-utils/Modules/CTALinkModule")

const AreaGuidesDetailBanner = ({ areaGuideData }) => {
  var imagename = "area-guide.banner_image.detail_image"

  let processedImages = JSON.stringify({})
  if (areaGuideData?.imagetransforms?.banner_image_Transforms) {
    processedImages = areaGuideData?.imagetransforms?.banner_image_Transforms
  }
  return (
    <div className="areaguides-detail-banner">
      <ImageModule
        ImageSrc={areaGuideData?.banner_image}
        altText={`${
          areaGuideData?.alternativeText
            ? areaGuideData?.alternativeText
            : areaGuideData.banner_title
        } banner`}
        imagetransforms={processedImages}
        renderer="bgImg"
        imagename={imagename}
        strapi_id={areaGuideData?.strapi_id}
        classNames="img-fluid banner-img"
      />
      <div className="overlay-bg"></div>
      <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
      <Container>
        <div className="banner-content">
          <h1>{areaGuideData?.banner_title}</h1>
          <p className="description">
            <ContentModule Content={areaGuideData?.content?.data?.content} />
          </p>
          <div className="cta-section">
            {areaGuideData?.cta1_title && (
              <CTALink
                class="button button-filled-green"
                link={
                  areaGuideData.cta1_link
                    ? areaGuideData.cta1_link
                    : { external_link: areaGuideData?.cta1_custom_link }
                }
                title={areaGuideData?.cta1_title}
                target_window={areaGuideData?.cta1_link?.target_window}
              />
            )}
            {areaGuideData?.cta2_title && (
              <CTALink
                class="button button-outline-white"
                link={
                  areaGuideData.cta2_link
                    ? areaGuideData.cta2_link
                    : { external_link: areaGuideData?.cta2_custom_link }
                }
                title={areaGuideData?.cta2_title}
                target_window={areaGuideData?.cta2_link?.target_window}
              />
            )}
          </div>
        </div>
      </Container>
      </ScrollAnimation>
    </div>
  )
}

export default AreaGuidesDetailBanner

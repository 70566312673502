import React from "react"
import { Container } from "react-bootstrap"
import "./PopularAreaComponent.scss"
import ScrollAnimation from "react-animate-on-scroll"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const PopularAreaComponent = ({ data }) => {
  return (
    <section className="popular-area-wrapper section_items" id="overview">
      <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
      <Container>
        <div className="popular-area-sections">
          <div className="popular-content_section">
            <h2 className="heading">{data.title}</h2>
            <p className="description">
              <ContentModule Content={data?.content?.data?.content} />
            </p>
          </div>
          {data?.list_items?.length > 0 && (
            <div className="popular-list-section">
              {data?.list_items?.map((item, index) => {
                return (
                  <div className="popular-list-item" key={item.area_name}>
                    <div className="image-section">
                      <img src={item.image?.url} alt={item.area_name} />
                    </div>
                    <h4 className="area-name">{item.area_name}</h4>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </Container>
      </ScrollAnimation>
    </section>
  )
}

export default PopularAreaComponent

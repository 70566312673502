import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import AccordianValuation from "../components/AccordianValuation/AccordianValuation"
import AreaGuideHeader from "../components/AreaGuideHeader/AreaGuideHeader"
import AreaGuidesDetailBanner from "../components/AreaGuidesDetailBanner/AreaGuidesDetailBanner"
import GuidesCardComponent from "../components/GuidesCardComponent/GuidesCardComponent"
import ImageContentComponent from "../components/ImageContentComponent/ImageContentComponent"
import PopularAreaComponent from "../components/PopularAreaComponent/PopularAreaComponent"
import Map from "../components/PropertyMap/Map"
import SecondaryAreaBanner from "../components/SecondaryAreaBanner/SecondaryAreaBanner"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AreaGuideDetails = ({ data }) => {
  const areaGuideData = data?.strapiAreaGuide

  const [id, setId] = useState([])

  let idArray

  useEffect(() => {
    setTimeout(() => {
      const nodeList =
        typeof document !== "undefined"
          ? document.querySelectorAll("section")
          : ""
      const array = [...nodeList]
      const filteredArray = array.filter(element => element.hasAttribute("id"))
      idArray = filteredArray.map(element => element.getAttribute("id"))
      if (idArray) {
        setId(idArray)
      }
    }, 500)
  }, [setId])

  const property_details_map_data = {
    latitude: areaGuideData?.latitude,
    longitude: areaGuideData?.longitude,
  }

  const PageData =
    data?.allStrapiPage?.edges?.length > 0
      ? data?.allStrapiPage?.edges[0]?.node
      : ""

  let popUpData = ""

  if (PageData?.add_page_modules?.length > 0) {
    const results = PageData?.add_page_modules?.filter(
      item => item.__typename === "STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT"
    )
    if (results?.length > 0) {
      popUpData = results[0]
    }
  }

  return (
    <Layout
      popUpData={popUpData}
      nonpropertyDetails
      isSeachpage={true}
      layout={"sticky-module"}
    >
      <AreaGuidesDetailBanner areaGuideData={areaGuideData} />
      {id?.length > 0 && <AreaGuideHeader idArray={id} area />}
      {areaGuideData.guide_content?.map((item, index) => {
        return (
          <div>
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_AREA_LISTINGS" && (
              <div>
                <PopularAreaComponent data={item} />
              </div>
            )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_SECONDARY_BANNER" && (
              <div>
                <SecondaryAreaBanner data={item} pageData={areaGuideData} />
              </div>
            )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_IMAGE_CONTENT" && (
              <div>
                <ImageContentComponent
                  data={item}
                  pageData={areaGuideData}
                  pageName="areaGuide"
                />
              </div>
            )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_ACCORDION" && (
              <AccordianValuation
                add_toggle={item?.add}
                title={item?.title}
                content={item?.content}
                accordianPage="area-guide-details"
              />
            )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&
              item?.select_module === "area_guide_map" && (
                <section className="area-guide-map section_items" id="map">
                  <Map
                    property_details_map_data={property_details_map_data}
                    areaGuide
                  />
                </section>
              )}

            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GUIDES_CARDS" && (
              <GuidesCardComponent data={item} />
            )}
          </div>
        )
      })}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const areaGuideData = data?.strapiAreaGuide

  let myimgtransforms = ""
  if (areaGuideData?.imagetransforms?.banner_image_Transforms) {
    myimgtransforms = JSON.parse(
      areaGuideData?.imagetransforms?.banner_image_Transforms
    )
  }

  let imageUrl=""

  const findImage = areaGuideData?.banner_image?.url?.substring(areaGuideData?.banner_image?.url?.indexOf("i.prod"));
  const ProcessedResult=findImage&&myimgtransforms[findImage]?myimgtransforms[findImage]["webp"]:""
  imageUrl=ProcessedResult?ProcessedResult["744x650"]:areaGuideData?.banner_image?.url

  const metaTitle = `${areaGuideData?.banner_title} Area Guide`
  const metaDesc = `Know more about ${areaGuideData?.banner_title} here. Contact one of our real estate agents for assistance in finding the right Properties for you in ${areaGuideData?.banner_title}.`

  return <SEO title={metaTitle} description={metaDesc} imageUrl={imageUrl}></SEO>
}

export default AreaGuideDetails

export const query = graphql`
  query ($page_id: Int) {
    strapiAreaGuide(strapi_id: { eq: $page_id }) {
      banner_image {
        url
        alternativeText
      }
      banner_title
      cta1_custom_link
      latitude
      longitude
      imagetransforms {
        tile_image_Transforms
        banner_image_Transforms
        image_content_image_Transforms
      }
      cta1_title
      cta2_link {
        slug
        strapi_parent {
          slug
        }
      }
      cta2_custom_link
      cta2_title
      content {
        data {
          content
        }
      }
      title
      strapi_id
      id

      guide_content {
        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_CONTENT {
          __typename
          id
          left_right_block {
            cta_label
            description {
              data {
                description
              }
            }
            image {
              alternativeText
              url
            }
            layout
            video_url
            title
            id_name
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          id
          __typename
          select_module
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_SECONDARY_BANNER {
          __typename
          id
          image {
            url
          }
          heading
          content {
            data {
              content
            }
          }
          description {
            data {
              description
            }
          }
          video_url
          title
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_GUIDES_CARDS {
          __typename
          id
          heading
          items {
            content {
              data {
                content
              }
            }
            image {
              alternativeText
              url
            }
            link {
              ...MenuFragment
            }
            cta_title
            custom_link
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
          __typename
          id
          add {
            content {
              data {
                content
              }
            }
            title
          }
          title
          content {
            data {
              content
            }
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_AREA_LISTINGS {
          __typename
          id
          content {
            data {
              content
            }
          }
          title
          list_items {
            area_name
            image {
              url
              alternativeText
            }
          }
        }
      }
    }

    allStrapiPage(filter: { layout: { eq: "area_guide" } }) {
      edges {
        node {
          title
          add_page_modules {
            ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
              __typename
              ...GlobalPopUpFragment
            }
          }
        }
      }
    }
  }
`
